setupAllNewsletterFeeds();

function setupAllNewsletterFeeds(){
    var feeds = $('.section--newsletter_feed');

    if(feeds.length > 0){
        feeds.each(function(i, el){
            setupSingleNewsletterFeed(el);
        })
    }
}

function setupSingleNewsletterFeed(feed){
    // Original Mailchimp Feed Items
    var feedItems = $(feed).find('.display_archive > .campaign');
    var feedList = $(feed).find('.newsletter-feed');
    var feedLang = $(feedList).data('lang');
    var feedLoadMoreBtn = $(feed).find('.load-more-newsletter');
    var itemsLoaded = 9; // Amount of items shown in feed

    $(feedLoadMoreBtn).on('click', function(){
        var hiddenItems = $(feedList).find('.not-loaded');
        // Show all items
        hiddenItems.removeClass('not-loaded');
        feedLoadMoreBtn.remove();
    });

    if(feedItems.length > 0){
        var updatedItems = createNewItems(feedItems)

        // Add our updated items to DOM
        populateNewItems(updatedItems);

        function createNewItems(rawItems){
            var newItems = [];
    
            rawItems.each((index, item) => {
                // Setup the data we need
                var rawText = item.innerText;
                var title = $(item).find('a').text();
                var link = $(item).find('a').attr('href');
                var dateRaw = rawText.match(/\d{2}\/\d{2}\/\d{4}/)[0];
                dayjs.extend(window.dayjs_plugin_customParseFormat)
                var date = dayjs(dateRaw, "DD/MM/YYYY");
    
                if(feedLang == 'fr'){
                    date = date.locale('fr').format('le D MMMM YYYY');
                } else {
                    date = date.format('MMMM DD YYYY');
                }

                // Setup our new item object
                var newItem = {
                    text: `<span class="title">${title}</span><span class="date">${date}</span>`,
                    link,
                }
    
                // Add our new item object to our array
                newItems.push(newItem);
            });
    
            // return our new items array of objects
            return newItems;
        }

        function populateNewItems(updatedItems){
            $(updatedItems).each((index, item) => {
                var itemListItem = document.createElement('li');
                var itemLink = document.createElement('a');
                itemLink.href = item.link;
                itemLink.innerHTML = item.text;
                itemLink.target = "_blank";

                if(index > itemsLoaded){
                    itemListItem.classList.add('not-loaded');
                }
    
                itemListItem.appendChild(itemLink);
                $(feedList).append(itemListItem);
            });
        }
    }
}