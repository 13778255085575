var inPageNavLinkClicked = false;
var scrollPositions = [];
var currentScrollPos = 0;

initInpageNav();

function initInpageNav(){
    var nav = $("#page-navigation");

    if(nav.length){
        initSmoothScroll(nav);
        highlightActiveItem(nav);
        initScrollButton(nav);
    }
}

function initSmoothScroll(nav){
    var scrollLinks = $(nav).find('a');
    var navHeight = $(nav).height();

    scrollLinks.on('click', function(event){
        event.preventDefault();
        var target = $($(this).attr('href'));
        inPageNavLinkClicked = true;
        headroom.unpin();
        headroom.freeze();

        $('html, body').animate({
            scrollTop: target.offset().top - (navHeight + 10)
        }, 1000, function(){
            headroom.unfreeze();
            inPageNavLinkClicked = false;
        })
    })
}

function initScrollButton(nav){
    var nextButton = $(nav).find('[data-scroll-next-item]');
    var prevButton = $(nav).find('[data-scroll-prev-item]');
    var scrollableDiv = $(nav).find('[data-scroll]')

    // Show button if div is scrollable
    if(scrollableDiv[0].scrollWidth === scrollableDiv[0].offsetWidth){
        $(nav).addClass('not-scrollable');
    }

    $(scrollableDiv).find('li').each(function(i, el){ 
        var itemLeftPos = $(el).position().left
        scrollPositions.push(itemLeftPos);
    });

    nextButton.on('click', function(){
        currentScrollPos++;
        $(scrollableDiv).animate({
            scrollLeft: scrollPositions[currentScrollPos]
        }, 150);

        updateButtons(nav);
    })

    prevButton.on('click', function(){
        currentScrollPos--;
        $(scrollableDiv).animate({
            scrollLeft: scrollPositions[currentScrollPos]
        }, 150);

        updateButtons(nav);
    })

}

function updateButtons(nav){
    var nextButton = $(nav).find('[data-scroll-next-item]');
    var prevButton = $(nav).find('[data-scroll-prev-item]');
    var scrollableDiv = $(nav).find('[data-scroll]')

    var hitLastItem = scrollableDiv[0].scrollWidth - Math.round(scrollPositions[currentScrollPos]) < scrollableDiv[0].offsetWidth;

    if(currentScrollPos === scrollPositions.length || hitLastItem){
        $(nextButton).removeClass('is-active');
    } else {
        $(nextButton).addClass('is-active');
    }

    if(currentScrollPos !== 0){
        $(prevButton).addClass('is-active');
    } else {
        $(prevButton).removeClass('is-active');
    }
}

function highlightActiveItem(nav){
    var lastId;
    var navHeight = $(nav).height();
    var navItems = $(nav).find("a");
    var scrollableDiv = $(nav).find('[data-scroll]')


    // Anchors corresponding to menu items
    var scrollItems = navItems.map(function() {
      var item = $($(this).attr("href"));
      if (item.length) {
        return item;
      }
    });

    // Bind to scroll
    $(window).scroll(function() {
        // Get container scroll position
        var fromTop = $(this).scrollTop() + (navHeight + 50);

        // Get id of current scroll item
        var cur = scrollItems.map(function() {
        if ($(this).offset().top < fromTop)
            return this;
        });

        // Get the id of the current element
        cur = cur[cur.length - 1];
        var id = cur && cur.length ? cur[0].id : "";

        if (lastId !== id && inPageNavLinkClicked === false) {
            lastId = id;

            navItems
                .parent().removeClass("active")
                .end().filter("[href='#" + id + "']").parent().addClass("active");

            var scrollElem = $(nav).find("a[href='#" + id + "']")[0];

            if(scrollElem){
                $(scrollableDiv).animate({
                    scrollLeft: $(scrollableDiv).scrollLeft() + $(scrollElem).position().left
                }, 150, function(){
                    updateButtons(nav);
                });
            }
        }
    });

}