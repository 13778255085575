// menu.js
const menu = setupMenu();
let activeSubMenu;

$(".menu-toggle").on('click', function(){
    menu.toggle();
});

$('.menu-item-has-children > a').on('click', function(event){
    if($(window).width() < 768){
        event.preventDefault(); 
        let subMenuDiv = $(this).siblings('.sub-menu');
        activeSubMenu = subMenu(subMenuDiv);
        activeSubMenu.open();
    }
});

$('button[data-close-sub-menu]').on('click', function(event){
    event.preventDefault();
    if(activeSubMenu){
        activeSubMenu.close();
        activeSubMenu = false;
    }
});

function setupMenu(){
    let menuIsOpen = false;
    const mainMenu = $('.main-menu');
    const mainToggle = $('.menu-toggle');

    function open(){
        headroom.pin();
        headroom.freeze();
        mainMenu.addClass('is-active');
        mainToggle.addClass('is-active');
        $('body').addClass('no-scroll');
        menuIsOpen = true;
        mainToggle.attr('aria-expanded', menuIsOpen);
    }

    function close(){
        headroom.unfreeze();
        mainMenu.removeClass('is-active');
        mainToggle.removeClass('is-active');
        $('body').removeClass('no-scroll');
        menuIsOpen = false;
        mainToggle.attr('aria-expanded', menuIsOpen);
    }

    function toggle(){
        if(menuIsOpen){
            close();
        } else {
            open();
        }
    }

    let publicAPI = {
        open,
        close,
        toggle
    }

    return publicAPI;
}

function subMenu(subMenuDiv){
    function open(){
        $(subMenuDiv).addClass('is-active');
    }

    function close(){
        $(subMenuDiv).removeClass('is-active');
    }

    //---------------------------------------------

    var publicAPI = {
        open,
        close
    }

    return publicAPI;
}