$(document).ready(function(){
    // Wait for the DOM to be ready before init
    setupAllRecentNewsSliders();
});

function setupAllRecentNewsSliders(){
    const sliders = $('.recent-news-swiper');

    if(sliders.length > 0){
        sliders.each(function(i, el){
            new Swiper(el, {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        allowTouchMove: true,
                    },
                    768: {
                        slidesPerView: 2.1,
                        slidesPerGroup: 2,
                        allowTouchMove: true,
                    },
                    1360: {
                        slidesPerView: 3.1,
                        slidesPerGroup: 3,
                        allowTouchMove: true,
                    }
                }
            });
        });
    }
}