$(document).ready(function(){
    // Wait for the DOM to be ready before init
    setupAllImageSliderBlocks();
});

function setupAllImageSliderBlocks(){
    const sliders = $('.image-swiper');

    if(sliders.length > 0){
        sliders.each(function(i, el){
            new Swiper(el, {
                slidesPerView: 1.5,
                spaceBetween: 30,
                dynamicBullets: true,
                centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            });
        });
    }
}