$('[data-search-toggle]').on('click', function(event){
    var section = $(this).parent();
    var searchInput = $(this).siblings('form').find('input[type="search"]'); 

    if($(section).hasClass('is-active') == false){
        if($(window).width() > 1472){
            $(section).addClass('is-active')
            anime({
                targets: '.section--filter-bar__search',
                maxWidth: ['13rem', '100%'],
                easing: 'easeOutExpo', 
                duration: 250,
                complete: () => {
                    $(searchInput).focus();
                }
            });
        }
    } else {
        $(section).removeClass('is-active')
        $(searchInput).val("").blur();
    }
})