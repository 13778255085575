var myElement = document.querySelector(".main-header");
var options = {
    tolerance : {
        up : 25,
        down : 10
    },
    // callback when above offset, `this` is headroom object
    onTop : function() {
        $('body').removeClass('header-not-top');
    },
    onNotTop : function() {
        $('body').addClass('header-not-top');
    },
    // callback when pinned, `this` is headroom object
    onPin : function() {
        $('body').addClass('header-pinned');
    },
    // callback when unpinned, `this` is headroom object
    onUnpin : function() {
        $('body').removeClass('header-pinned');
    },
}

var headroom  = new Headroom(myElement, options);
headroom.init();